<template>
  <div class="content">
    <h1 class="title title--big title--indent title--color">Импорт карт Газпромбанка</h1>
    <div class="content__party content__party--start">
      <UploaderExcel
        @fileUpload="fetchLogs"
        :extensions="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
        btnTitle="Загрузить файл Excel (.xlsx)"
        url="card-gpb/parse"
      />
      <a href="https://storage.yandexcloud.net/ruy-mkp/import_templates/gpb_card_import_example.xlsx" download class="button button--light button--no-indent button--indent-left">
        <img src="@/assets/img/example-import.svg" alt="">
        Скачать пример файла
      </a>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
            :options="limitOptions"
            :selected="limitSelected"
            :closeOnOutsideClick="true"
            @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      v-show="table.show"
      :data="table.logs"
      :columns="table.columns"
      :options="table.options"
      ref="cardsFreeTable"
      class="table-default">
      <div slot="name" slot-scope="props">
        <button class="table-default__button" @click="downloadImport(props.row.file_url, props.row.name)">Скачать</button>
      </div>
      <div slot="error_count" slot-scope="props">
        <button v-if="props.row.error_count > 0" class="table-default__button" @click="downloadError(props.row.id, props.row.name)">
          {{ props.row.error_count }}
        </button>
        <span v-else>{{ props.row.error_count }}</span>
      </div>
      <div slot="updated_at" slot-scope="props">
        <span v-if="!props.row.in_process">{{ props.row.updated_at }}</span>
        <div v-else class="loading loading--mini" >
          <div class="loading__body">
            <div class="effect-1 loading__effects"></div>
            <div class="effect-2 loading__effects"></div>
            <div class="effect-3 loading__effects"></div>
          </div>
        </div>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
    <SidebarRight
      title="Информация об импорте"
      :class="{'sidebar-manage--full': showSidebar}"
      @close-sidebar="showSidebar = !showSidebar"
    >
      <div class="sidebar-card">
        <div class="sidebar-card__bottom">
          <div class="sidebar-card__item sidebar-card__item--between">
            <b>ID импорта</b> <span>{{ dataSidebar.id }}</span>
          </div>
          <div class="sidebar-card__item sidebar-card__item--between">
            <b>Имя импорта</b> <span>{{ dataSidebar.name }}</span>
          </div>
        </div>
      </div>
    </SidebarRight>
    <div class="loading loading--content" id="loading" v-if="!table.show">
      <div class="loading__body">
        <div class="effect-1 loading__effects"></div>
        <div class="effect-2 loading__effects"></div>
        <div class="effect-3 loading__effects"></div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
import SidebarRight from '@/components/SidebarRight';
import UploaderExcel from '@/components/UploaderExcel';
import axiosAdmin from '@/http/axios/axiosAdmin';

export default {
  name: 'Cards',
  components: { dropdown, SidebarRight, UploaderExcel },
  data() {
    return {
      table: {
        logs: [],
        columns: ['id', 'name', 'success_count', 'error_count', 'created_at', 'updated_at'],
        options: {
          headings: {
            id: 'ID',
            name: 'Файл импорта',
            success_count: 'Успешно импортировано',
            error_count: 'Ошибок импорта',
            created_at: 'Начат',
            updated_at: 'Окончен'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback() {
            return 'table-default__row';
          },
          rowAttributesCallback(row) {
            return { 'data-id': row.id };
          }
        },
        show: false
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: this.$store.state.admin.tableLimit },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      showSidebar: false,
      dataSidebar: {}
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchLogs(1, this.limitSelected.name);
  },
  methods: {
    fetchLogs(page = 1, limit) {
      this.$store.dispatch('admin/cardsGpb/GET_IMPORT', { page, limit })
        .then(response => {
          this.table.logs = response.data.results;
          this.table.show = true;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
          console.log(response.data.results);
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${error.message}`
          });
        });
    },
    updateLimiter(data) {
      this.$store.commit('admin/changeTableLimit', data.name);
      this.$refs.cardsFreeTable.setLimit(this.$store.state.admin.tableLimit);
      this.fetchLogs(1, this.$store.state.admin.tableLimit);
    },
    clickPaginationCallback(page) {
      this.fetchLogs(page, this.$store.state.admin.tableLimit);
    },
    returnStart() {
      return (this.pagination.page * this.table.logs.length) - (this.table.logs.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.logs.length - 1;
    },
    downloadImport(url, fileName) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const blobURL = URL.createObjectURL(blob);
          if (fileName.length && url.length) {
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = fileName;
            a.style = 'display: none';
            a.click();
          }
        })
        .catch(err => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка:${err.message}`
          });
        });
    },
    downloadError(id, name) {
      axiosAdmin.get(`card-gpb/log/${id}/report/`, { responseType: 'blob' })
        .then(blob => {
          const blobURL = URL.createObjectURL(new Blob([blob.data]));
          const a = document.createElement('a');
          a.href = blobURL;
          a.download = `report_${name}.xlsx`;
          a.style = 'display: none';
          a.click();
        })
        .catch(err => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка:${err.message}`
          });
        });
    },
  }
};
</script>